import React from 'react';
import ExternalContent from './ExternalContent';

const App = () => {
  return (
    <div>
      <ExternalContent />
    </div>
  );
};

export default App;